<template>
  <div class="createPost-container">
    <el-form ref="postForm" :model="postForm" :rules="rules" class="form-container">
      <sticky :class-name="'sub-navbar '+postForm.status">
        <!--CommentDropdown v-model="postForm.comment_disabled" /-->
        <!--PlatformDropdown v-model="postForm.platforms" /-->
        <!--SourceUrlDropdown v-model="postForm.source_uri" /-->
        <el-button
          v-loading="loading"
          style="margin-left: 10px;"
          type="success"
          icon="el-icon-s-promotion"
          @click="submitForm"
        >
          Publier
        </el-button>
        <el-button v-loading="loading" icon="el-icon-document" type="info" @click="draftForm">
          Sauvegarder
        </el-button>
      </sticky>

      <div class="createPost-main-container">
        <el-row>
          <el-col :span="24">

            <el-row>
              <el-col :span="12">
                <el-form-item style="margin-bottom: 40px;" prop="title">
                  <MDinput v-model="postForm.title" :maxlength="100" name="name" required>
                    Nom
                  </MDinput>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item style="margin-bottom: 40px;" prop="scientifique_name">
                  <MDinput v-model="postForm.scientifique_name" name="scientifique_name" required>
                    Nom scientique
                  </MDinput>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="postInfo-container">
              <el-row>
                <el-col :span="12">
                  <el-form-item label-width="80px" label="Category:" class="postInfo-container-item">
                    <el-select v-model="postForm.categorie_id" class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('categorie.label')" style="width: 100%;">
                      <el-option
                        v-for="(categorie, index) in categories"
                        :key="index"
                        :label="categorie.label | uppercaseFirst"
                        :value="categorie.id"
                        :disabled="!categorie.is_active"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    label-width="120px"
                    label="Publier le:"
                    class="postInfo-container-item"
                  >
                    <el-date-picker
                      v-model="postForm.display_time"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      placeholder="Selectionnez la date"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label-width="80px" label="Tags:" class="postInfo-container-item">
              <el-select v-model="postForm.tags" multiple placeholder="Select">
                <el-option-group
                  v-for="group in tags"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="tag in group.tags"
                    :key="tag.id"
                    :label="tag.label | uppercaseFirst"
                    :value="tag.id"
                    :disabled="!tag.is_active"
                  />
                </el-option-group>
              </el-select>
              <!-- <el-select v-model="postForm.tags" multiple placeholder="Select">
                <el-option
                  v-for="tag in tags"
                  :key="tag.id"
                  :label="tag.label | uppercaseFirst"
                  :value="tag.id"
                  :disabled="!tag.is_active"
                />
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label-width="80px"
              label="Important:"
              class="postInfo-container-item"
            >
              <el-rate
                v-model="postForm.importance"
                :max="3"
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
                :low-threshold="1"
                :high-threshold="3"
                style="margin-top:8px;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label-width="100px"
              label="Allergènes:"
              class="allergenes"
            >
              <el-switch
                v-model="postForm.wheat"
                active-text=""
                inactive-text="Blé"
                style="margin-right:20px"
              />
              <el-switch
                v-model="postForm.gluten"
                active-text=""
                inactive-text="Gluten"
                style="margin-right:20px"
              />
              <el-switch
                v-model="postForm.egg"
                active-text=""
                inactive-text="Oeuf"
                style="margin-right:20px"
              />
              <el-switch
                v-model="postForm.milk"
                active-text=""
                inactive-text="Lait"
                style="margin-right:20px"
              />
              <el-switch
                v-model="postForm.nuts"
                active-text=""
                inactive-text="Noix"
                style="margin-right:20px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Origine" prop="origine">
              <el-input v-model="postForm.origine" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Saison de disponibilité" prop="saison">
              <el-input v-model="postForm.saison" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Prix par tonne (cif)" prop="prix_tonne_cif">
              <el-input v-model="postForm.prix_tonne_cif" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Prix par tonne (fob)" prop="prix_tonne_fob">
              <el-input v-model="postForm.prix_tonne_fob" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Varité" prop="varite">
              <el-input v-model="postForm.varite" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Specifications : calibre / autres données qualités" prop="specifications">
              <el-input v-model="postForm.specifications" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="nutritional-valaue" style="margin-top:15px;margin-bottom:15px">
              <h3 style="margin:1px">Valeurs nutritionnelles</h3>
              <el-form-item label="Ajoutez et tapez Entrer" prop="label">
                <el-input v-model="addNutrivalue" style="margin-bottom:8px" @keyup.enter.native="addNutritionalValue" />
                <el-table
                  :data="postForm.nutritionalvalues.filter(data => !nutritionalvaluesSearch || data.toLowerCase().includes(nutritionalvaluesSearch.toLowerCase()))"
                  style="width: 100%"
                >
                  <el-table-column label="Liste">
                    <template slot-scope="scope">
                      {{ scope.row }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="right"
                  >
                    <template slot="header">
                      <el-input
                        v-model="nutritionalvaluesSearch"
                        size="mini"
                        placeholder="Rechercher"
                      />
                    </template>
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        icon="el-icon-edit"
                        @click="handleEdit(scope.$index, scope.row)"
                      />
                      <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        @click="handleDelete(scope.$index, scope.row)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </div>
          </el-col>

        </el-row>
        <el-form-item style="margin-bottom: 40px;" label-width="80px" label="Sommaire:">
          <el-input
            v-model="postForm.content_short"
            :rows="1"
            type="textarea"
            class="article-textarea"
            autosize
            placeholder="Veillez entrer undétails"
          />
          <span v-show="contentShortLength" class="word-counter">{{ contentShortLength }} Caractères</span>
        </el-form-item>

        <el-form-item prop="content" style="margin-bottom: 30px;">
          <Tinymce ref="editor" v-model="postForm.content" :height="400" />
        </el-form-item>

        <el-form-item prop="image_uri" style="margin-bottom: 30px;">
          <Upload
            v-model="postForm.image_uri"
            :uploadurl="thumbPostUrl"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Tinymce from '@/components/Tinymce';
import Upload from '@/components/Upload/SingleImage';
import MDinput from '@/components/MDinput';
import Sticky from '@/components/Sticky'; // Sticky header
// import { validURL } from '@/utils/validate';
import { fetchArticle } from '@/api/article';
import Resource from '@/api/resource';
const articleResource = new Resource('articles');
const categorieResource = new Resource('categories');
const tagResource = new Resource('tags');
import {
  CommentDropdown,
  // PlatformDropdown,
  // SourceUrlDropdown,
} from './Dropdown';

const defaultForm = {
  status: 'draft',
  title: '',
  content: '',
  content_short: '',
  source_uri: '',
  image_uri: '',
  display_time: undefined,
  id: undefined,
  platforms: ['a-platform'],
  comment_disabled: false,
  importance: 0,
  nutritionalvalue: {},
};

export default {
  name: 'ArticleDetail',
  components: {
    Tinymce,
    MDinput,
    Upload,
    Sticky,
    CommentDropdown,
    // PlatformDropdown,
    // SourceUrlDropdown,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateRequire = (rule, value, callback) => {
      if (value === '') {
        this.$message({
          message: rule.field + ' is required',
          type: 'error',
        });
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    /* const validateSourceUri = (rule, value, callback) => {
      if (value) {
        if (validURL(value)) {
          callback();
        } else {
          this.$message({
            message: 'External URL is invalid.',
            type: 'error',
          });
          callback(new Error('External URL is invalid.'));
        }
      } else {
        callback();
      }
    };*/
    return {
      addNutrivalue: '',
      currentEdition: '',
      nutritionalvaluesSearch: '',
      thumbPostUrl: this.$appbaseUrl + 'upload.php',
      tags: [],
      categories: [],
      query: {
        page: 1,
        limit: 0,
      },
      postForm: Object.assign({}, defaultForm),
      loading: false,
      userListOptions: [],
      rules: {
        image_uri: [{ validator: validateRequire }],
        title: [{ validator: validateRequire }],
        content: [{ validator: validateRequire }],
        // source_uri: [{ validator: validateSourceUri, trigger: 'blur' }],
      },
      tempRoute: {},
    };
  },
  computed: {
    contentShortLength() {
      return this.postForm.content_short.length;
    },
    lang() {
      return this.$store.getters.language;
    },
  },
  created() {
    this.getCategoriesList();
    this.getTagsList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(id);
    } else {
      this.postForm = Object.assign({}, defaultForm);
    }
    this.getBase64Image(this.$postThumUrl + this.postForm.image_uri, this.converted, 'jpg');
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of the setTagsViewTitle function, this.$route is no longer pointing to the current page
    this.tempRoute = Object.assign({}, this.$route);
  },
  methods: {
    addNutritionalValue() {
      const newValue = this.addNutrivalue;
      if (!newValue){
        this.$message({
          message: 'Veuillez saisir une valeur !',
          type: 'error',
          duration: 5 * 1000,
        });
        return;
      }
      if (this.currentEdition && this.postForm.nutritionalvalues.includes(this.currentEdition)){
        this.postForm.nutritionalvalues.splice(this.postForm.nutritionalvalues.indexOf(this.currentEdition), 1);
      }
      if (!this.postForm.nutritionalvalues.includes(newValue)){
        this.postForm.nutritionalvalues.push(newValue);
      } else {
        this.$message({
          message: 'Déjà ajouté !',
          type: 'error',
          duration: 5 * 1000,
        });
        // this.postForm.nutritionalvalues.splice(this.postForm.nutritionalvalues.indexOf(newValue), 1);
      }
      this.addNutrivalue = '';
    },
    handleEdit(index, row) {
      this.currentEdition = row;
      this.addNutrivalue = row;
    },
    handleDelete(index, row) {
      this.postForm.nutritionalvalues.splice(index, 1);
    },
    converted(data){
      this.postForm.image_uri = data;
    },
    getBase64Image(src, callback, outputFormat) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL = '';
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    async getCategoriesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await categorieResource.list(this.query);
      this.categories = data;
      this.categories.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getTagsList() {
      this.loading = true;
      this.tags = await tagResource.list(this.query);
      this.loading = false;
    },
    fetchData(id) {
      fetchArticle(id)
        .then(response => {
          this.postForm = response.data;
          this.setTagsViewTitle();
        })
        .catch(err => {
          console.log(err);
        });
    },
    setTagsViewTitle() {
      const title =
      this.lang === 'Edit Article';
      const route = Object.assign({}, this.tempRoute, {
        title: `${title}-${this.postForm.id}`,
      });
      this.$store.dispatch('updateVisitedView', route);
    },
    updateArticle() {
      this.loading = true;
      articleResource.update(this.postForm.id, this.postForm).then(response => {
        this.$notify({
          title: 'Success',
          message: 'Article publié avec succès',
          type: 'success',
          duration: 2000,
        });
        this.dialogCreateArticle = false;
        this.loading = false;
      })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitForm() {
      // this.postForm.display_time = parseInt(this.display_time / 1000);
      this.$refs.postForm.validate(valid => {
        if (valid) {
          this.postForm.status = 'publié';
          this.updateArticle();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    draftForm() {
      if (
        this.postForm.content.length === 0 ||
        this.postForm.title.length === 0
      ) {
        this.$message({
          message: 'Please enter required title and content',
          type: 'warning',
        });
        return;
      }
      this.postForm.status = 'draft';
      this.updateArticle();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "~@/styles/mixin.scss";

.el-col-6,.el-col-12,.el-col-24 {
    padding-right: 5px;
    padding-left: 5px;
}

.createPost-container {
  position: relative;
  .createPost-main-container {
    padding: 0 45px 20px 50px;
    .postInfo-container {
      position: relative;
      @include clearfix;
      margin-bottom: 10px;
      .postInfo-container-item {
        float: left;
      }
    }
  }
  .word-counter {
    width: 40px;
    position: absolute;
    right: -10px;
    top: 0px;
  }
}

.createPost-container label.el-form-item__label {
  text-align: left;
}
</style>
